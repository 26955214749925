<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>点检任务</span>
      </div>
      <div class="box-content">
        <div>
          <div class="title">点检设备数</div>
          <div class="data"> {{ data.deviceCount }}</div>
        </div>
        <div>
          <div class="title">已点检设备数</div>
          <div class="data"> {{ data.completeCount }}</div>
        </div>
        <div>
          <div class="title">未点检设备数</div>
          <div class="data"> {{ data.incompleteCount }}</div>
        </div>
        <div>
          <div class="title">点检异常设备数</div>
          <div class="data"> {{ data.abnormalCount }}</div>
        </div>
        <div>
          <div class="title">漏点检设备数</div>
          <div class="data"> {{ data.missCount }}</div>
        </div>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="alias" label="设备别名"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
          <el-table-column prop="taskCode" label="点检代号"></el-table-column>
          <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
          <el-table-column prop="facilityName" label="设施位置"></el-table-column>        
          <el-table-column prop="deviceStatus" label="设备状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.deviceStatus == 1">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.deviceStatus == 2">异常</el-tag>
              <el-tag type="warning" v-if="scope.row.deviceStatus == 0 || scope.row.taskStatus == 2">未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="任务状态">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.taskStatus == 0">未点检</el-tag>
              <el-tag type="success" v-if="scope.row.taskStatus == 1">已点检</el-tag>
              <el-tag type="danger" v-if="scope.row.taskStatus == 2">已遗漏</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="completeTime" label="时间段">
            <template slot-scope="scope">
              {{ dateSecond(scope.row.startTime) }} - 
              {{ dateSecond(scope.row.endTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="staffNames" label="点检人">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.staffNames" :key="index">
                {{ scope.row.staffNames.length-1 > index ? item+'、' : item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="completeTime" label="完成时间">
            <template slot-scope="scope">
              {{ scope.row.completeTime ? dayjs(scope.row.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
        </el-table>
        <page :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';

export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      data: {}
    }
  },
  methods: {
    dayjs,
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60
        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },
    loadData() {
      this.$ajax.post('managementStatistics', {
        taskType: 1
      }).then(res => {
        this.data = res.data
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadList()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadList()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadList() {
      this.loading = true
      this.$ajax.post('managementNews',{
        taskType: 1,
        pageNum: this.page,
        pageSize: this.size
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadData()
    this.loadList()
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.box-content {
    display: flex;
    padding-bottom: 20px;
    >div {
      flex: 1;
      padding: 16px;
      height: 76px;
      margin: 0 10px;
      color: #333;
      background: #f5f7fa;
      box-sizing: border-box;
    }
    .title {
      font-size: 12px;
      color: #999;
    }
    .data {
      font-size: 24px;
      text-align: right;
    }
  }
</style>